

export const ScreenSize = {
    mobile:768,
    tablet:992,
    laptop:1324,
    desktop:2024
}




