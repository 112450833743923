const data = [
  {
    id: 1,
    name: "About",
    path: "/about",
  },
  {
    id: 2,
    name: "Contact",
    path: "/contact",
  },
  {
    id: 3,
    name: "Membership",
    path: "/member",
  },
  {
    id: 4,
    name: "Nysc Scheme",
    path: "/nysc",
  },
  {
    id: 5,
    name: "Examination",
    path: "/exam-info",
  },
  {
    id: 6,
    name: "Certification",
    path: "/certification",
  },
];

export default data;
